body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  display: flex;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

code {
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

input.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}

.MuiFormHelperText-root.Mui-error {
  padding: 0;
  margin: 0;
  margin-top: 4px;
}